
.link-header {
  color: black;
  font-size: 1.5em;
  padding: 5px;
  display: block;
}

.App {
  font-family: 'SourceSansPro-Black';
  text-align: center;
  padding: 10px;
  background-color: #F1ECE6;
}

video {
  max-width: 100%;
  height: auto;
}

html, body, #root, #top {
  height: 100%;
  font-weight: 100;
}

.home {
  font-family: 'SourceSansPro-Black';
  height: 100%;
  /* Location of the image */
  background-image: url(https://famille.solognot.net/thumbnail%2Fimages%2Fbackground.jpg);
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  /* Background image doesn’t tile */
  background-repeat: no-repeat;
  /* Background image is fixed in the viewport so that it doesn’t move when the content’s height is greater than the image’s height */
  background-attachment: fixed;
  /* This is what makes the background image rescale based on the container’s size */
  background-size: contain;
  /* Set a background color that will be displayed while the background image is loading */
  background-color: #F1ECE6;
}


.cartouche-container {
  position: relative;
}

.cartouche {
  position: absolute;
  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;
  /* Background image doesn’t tile */
  background-repeat: no-repeat;
  /* Background image is fixed in the viewport so that it doesn’t move when the content’s height is greater than the image’s height */
  /* background-attachment: fixed; */
  /* This is what makes the background image rescale based on the container’s size */
  background-size: contain;
  /* Set a background color that will be displayed while the background image is loading */
  background-color: #f7ead3;
  border: 2px solid #a1641d;
}

.react-photo-album img {
  border: 2px solid black;
}

.react-photo-album h3 {
  font-weight: 100;
}

.retour {
  border-radius: 20px;
}